import React from 'react'

const ReportCover = ({imageSrc}) => {
  return (
    <div className="report-cover-container">
      <div className="col">
        <img
          src={ imageSrc ?? "https://unsplash.com/photos/Cecb0_8Hx-o/download?ixid=MnwxMjA3fDB8MXxhbGx8fHx8fHx8fHwxNjczMDA0NjE2&force=true&w=640"}
          alt="image_src"
          className="h-40"
        />
      </div>
    </div>
  )
}

export default ReportCover
