import React from 'react'
import './reportbody.scss'

const ReportBody = ({ isBlog, imageUrl, shortDescription, longDescription }) => {
  return (
    <div className="report-body-container margin-auto">
      <div className="row">
        <p className="text-bold line-ht-30">
          {shortDescription ?? `No Description`}
        </p>
      </div>
      <div className={`row jt-cn ${isBlog ? "hidden" : ""}`}>
        <div className="w-30 mr-30">
          <img
            className="br-20"
            src={imageUrl ?? `https://picsum.photos/id/17/500/350`}
            alt="report_thumbnail"
          />
        </div>
        <div className="col w-20">
          <div>
            <p className="text-bold">Industry</p>
            <p>Travel & Tourism</p>
          </div>
          <div>
            <p className="text-bold">Outcome</p>
            <p>Industry insights</p>
            <p>Go-to-Market Strategy</p>
          </div>
        </div>
      </div>
      <div className="col">
        <p className="line-ht-40">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Volutpat id
          enim ac egestas nibh ac tellus. Vestibulum tempor non arcu, donec
          scelerisque elementum molestie volutpat elit. Eget turpis consectetur
          netus aliquam dolor, sapien. At sapien cursus quis molestie ut integer
          vitae. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          Volutpat id enim ac egestas nibh ac tellus. Vestibulum tempor non
          arcu, donec scelerisque elementum molestie volutpat elit. Eget turpis
          consectetur netus aliquam dolor, sapien. At sapien cursus quis
          molestie ut integer vitae. Lorem ipsum dolor sit amet, consectetur
          adipiscing elit. Volutpat id enim ac egestas nibh ac tellus.
          Vestibulum tempor non arcu, donec scelerisque elementum molestie
          volutpat elit. Eget turpis consectetur netus aliquam dolor, sapien. At
          sapien cursus quis molestie ut integer vitae. Lorem ipsum dolor sit
          amet, consectetur adipiscing elit. Volutpat id enim ac egestas nibh ac
          tellus. Vestibulum tempor non arcu, donec scelerisque elementum
          molestie volutpat elit. Eget turpis consectetur netus aliquam dolor,
          sapien. At sapien cursus quis molestie ut integer vitae. Lorem ipsum
          dolor sit amet, consectetur adipiscing elit. Volutpat id enim ac
          egestas nibh ac tellus. Vestibulum tempor non arcu, donec scelerisque
          elementum molestie volutpat elit. Eget turpis consectetur netus
          aliquam dolor, sapien. At sapien cursus quis molestie ut integer
          vitae. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          Volutpat id enim ac egestas nibh ac tellus. Vestibulum tempor non
          arcu, donec scelerisque elementum molestie volutpat elit. Eget turpis
          consectetur netus aliquam dolor, sapien. At sapien cursus quis
          molestie ut integer vitae. Lorem ipsum dolor sit amet, consectetur
          adipiscing elit. Volutpat id enim ac egestas nibh ac tellus.
          Vestibulum tempor non arcu, donec scelerisque elementum molestie
          volutpat elit. Eget turpis consectetur netus aliquam dolor, sapien. At
          sapien cursus quis molestie ut integer vitae. Lorem ipsum dolor sit
          amet, consectetur adipiscing elit. Volutpat id enim ac egestas nibh ac
          tellus. Vestibulum tempor non arcu, donec scelerisque elementum
          molestie volutpat elit. Eget turpis consectetur netus aliquam dolor,
          sapien. At sapien cursus quis molestie ut integer vitae.{' '}
        </p>
        <p className="line-ht-40">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Volutpat id
          enim ac egestas nibh ac tellus. Vestibulum tempor non arcu, donec
          scelerisque elementum molestie volutpat elit. Eget turpis consectetur
          netus aliquam dolor, sapien. At sapien cursus quis molestie ut integer
          vitae. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          Volutpat id enim ac egestas nibh ac tellus. Vestibulum tempor non
          arcu, donec scelerisque elementum molestie volutpat elit. Eget turpis
          consectetur netus aliquam dolor, sapien. At sapien cursus quis
          molestie ut integer vitae. Lorem ipsum dolor sit amet, consectetur
          adipiscing elit. Volutpat id enim ac egestas nibh ac tellus.
          Vestibulum tempor non arcu, donec scelerisque elementum molestie
          volutpat elit. Eget turpis consectetur netus aliquam dolor, sapien. At
          sapien cursus quis molestie ut integer vitae. Lorem ipsum dolor sit
          amet, consectetur adipiscing elit. Volutpat id enim ac egestas nibh ac
          tellus. Vestibulum tempor non arcu, donec scelerisque elementum
          molestie volutpat elit. Eget turpis consectetur netus aliquam dolor,
          sapien. At sapien cursus quis molestie ut integer vitae. Lorem ipsum
          dolor sit amet, consectetur adipiscing elit. Volutpat id enim ac
          egestas nibh ac tellus. Vestibulum tempor non arcu, donec scelerisque
          elementum molestie volutpat elit. Eget turpis consectetur netus
          aliquam dolor, sapien. At sapien cursus quis molestie ut integer
          vitae. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          Volutpat id enim ac egestas nibh ac tellus. Vestibulum tempor non
          arcu, donec scelerisque elementum molestie volutpat elit. Eget turpis
          consectetur netus aliquam dolor, sapien. At sapien cursus quis
          molestie ut integer vitae. Lorem ipsum dolor sit amet, consectetur
          adipiscing elit. Volutpat id enim ac egestas nibh ac tellus.
          Vestibulum tempor non arcu, donec scelerisque elementum molestie
          volutpat elit. Eget turpis consectetur netus aliquam dolor, sapien. At
          sapien cursus quis molestie ut integer vitae. Lorem ipsum dolor sit
          amet, consectetur adipiscing elit. Volutpat id enim ac egestas nibh ac
          tellus. Vestibulum tempor non arcu, donec scelerisque elementum
          molestie volutpat elit. Eget turpis consectetur netus aliquam dolor,
          sapien. At sapien cursus quis molestie ut integer vitae.{' '}
        </p>
        <p className="line-ht-40 text-bold">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Volutpat id
          enim ac egestas nibh ac tellus. Vestibulum tempor non arcu, donec
          scelerisque elementum molestie volutpat elit. Eget turpis consectetur
          netus aliquam dolor, sapien. At sapien cursus quis molestie ut integer
          vitae. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          Volutpat id enim ac egestas nibh ac tellus. Vestibulum tempor non
          arcu, donec scelerisque elementum molestie volutpat elit. Eget turpis
          consectetur netus aliquam dolor, sapien. At sapien cursus quis
          molestie ut integer vitae.{' '}
        </p>
      </div>
      <div className="divider mt-5"></div>

      <div className="row  mt-5">
        <div>
          <p className="color-gray">Author</p>
          <p>John Doe</p>
        </div>
        <div>
          <p className="color-gray">Category</p>
          <p>Sales & Marketing</p>
        </div>
        <div>
          <p className="color-gray">Date Created</p>
          <p>10/09/2020</p>
        </div>
      </div>
    </div>
  )
}

export default ReportBody
