import React from 'react'
import './reportheader.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons'
const ReportHeader = () => {
  return (
    <div className="report-header-container  margin-auto">
      <div className="col mt-5">
        {' '}
        <h2 className="letter-sp-2">
          {' '}
          How to create surveys which will produce maximum results.
        </h2>
      </div>
      <div className="row  h-25 w-30">
        <div className="row w-12">
          <img
            src="https://unsplash.com/photos/yBVCwHbx_74/download?force=true&w=640"
            alt="user_profile"
            className="user-profile"
          />
          <div className="col w-5">
            <span className="text-bold">User Name</span>
            <span className="mt-5">Occupation</span>
          </div>
        </div>
        <div>
          <span>10/09/2022</span>
        </div>
      </div>
      <div className="row  h-25 w-30">
        <div className="row w-12">
          <h2 className="text-bold">Share</h2>
          <div className="row">
            <FontAwesomeIcon
              icon={faLinkedin}
              width={'40'}
              fontSize={'25'}
              color={'##404040'}
              className={'mr-5'}
            />
            <FontAwesomeIcon
              icon={faTwitter}
              width={'40'}
              fontSize={'25'}
              color={'##404040'}
              className={'mr-5'}
            />
            <FontAwesomeIcon
              icon={faFacebook}
              width={'40'}
              fontSize={'25'}
              color={'##404040'}
              className={'mr-5'}
            />
            <FontAwesomeIcon
              icon={faInstagram}
              width={'40'}
              fontSize={'25'}
              color={'##404040'}
              className={'mr-5'}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ReportHeader
