import React from 'react'
import ReportBody from '../../../components/ReportsDetails/ReportBody/ReportBody'
import ReportCover from '../../../components/ReportsDetails/ReportCover/ReportCover'
import ReportHeader from '../../../components/ReportsDetails/ReportHeader/ReportHeader'
import Footer from '../../../components/Footer'
import BlogCover from '../../../images/blog-images/blogCoverImage.png'
import { useLocation } from '@reach/router'

import './blogdetails.scss'
import { BlogCards } from '../../../components/Card/BlogCards/BlogCards'
import { graphql, useStaticQuery } from 'gatsby'

/* export const query = graphql`
allContentfulBlog(filter: {id: {eq: "2b794438-9472-5d6f-88bf-be4352e22c96"}}) {
  edges {
    node {
      authorsName
      authorsProfession
      blogTitle
      id
    }
  }
}
`; */

const BlogDetails = () => {
  // console.log({ data })
  return (
    <div>
      <h1>Blog Template</h1>
      {/* Blog Details  */}
    </div>
  )
}

export default BlogDetails
